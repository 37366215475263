import { datadogRum } from '@datadog/browser-rum';

const DD_SITE = 'datadoghq.eu';
const SAM_SERVICE_NAME = 'msam.planning.sam-webapp';
const NOT_AVAILABLE = 'n/a';
// these are public data, no need to go under env variables https://bit.ly/3xmwbh5.
const APPLICATION_ID = '7b34b3a9-5d04-4856-a20e-a363303c9642';
const DD_RUM_CLIENT_TOKEN = 'pub7a43ee14d3d3ee61dfea5bce1c19efa8';

const isProd = () => window.samEnv?.ENVIRONMENT === 'PROD';
const isPp = () => window.samEnv?.ENVIRONMENT === 'PP';

const isBrowserRUMEnabled = () => isPp() || isProd();

const initDatadogRum = () => {
  if (!isBrowserRUMEnabled()) {
    return;
  }

  // @ts-ignore
  const { BUILD_VERSION, ENVIRONMENT } = window.samEnv;

  datadogRum.init({
    applicationId: APPLICATION_ID,
    clientToken: DD_RUM_CLIENT_TOKEN,
    service: SAM_SERVICE_NAME,
    version: BUILD_VERSION ?? NOT_AVAILABLE,
    env: ENVIRONMENT?.toLowerCase(),
    site: DD_SITE,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
    actionNameAttribute: 'tracking-id',
    allowedTracingUrls: ['https://sam-salesforce-pp.metro-cc.com', 'https://sam-salesforce.metro-cc.com'],
    beforeSend: () => {
      return process.env.NODE_ENV !== 'development';
    },
  });
};

export { initDatadogRum };
